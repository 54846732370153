import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { Backdrop, Fade, Modal, Theme, createStyles, makeStyles } from '@material-ui/core';
// import AddMonthlyActivity from './addMonthlyActivity';
import Select from "react-select";
import { clubGenderTypes } from './selectItems';
 
export interface belongToInfo {
    belongTo?: number;
    jiyeokId?: number;
    jiguId?: number;
}

interface ChildProps {
    fire: () => void,
    club: number,
    belonging: belongToInfo,
}



const AddClubInfo: React.FC<ChildProps> = (props: ChildProps) => {
    const { register, handleSubmit, getValues, setValue } = useForm();
    // const location = useLocation<Location>();
    const [clubList, setClubList] = useState([]);
    const [sponsorClub, setSponsorClub] = useState("");
    const [isClubOnly, setIsClubOnly] = useState(false);
    const [volunteerModalOpen, setVolunteerModalOpen] = useState(false);
    const [selectedClubType, setSelectedClubType] = useState({value:0, label:'클럽타입 선택'})
 
    useEffect(() => {
        // props.memberId
        axios.get(comm.SERVER_URL + "/group/clubInfo/", {
            params:{ id: props.club }
        }).then(res => {
            let resultData = res.data.clubInfo; 
            setValue("inputClubInitDate", resultData?.startDay);
            setValue("inputOrgNo", resultData?.jiguNo);
            setValue("inputInternationalNo", resultData?.gukjeNo);
            setValue("inputSponsorClub", resultData?.sponsorClubName);
            setValue("inputAddress", resultData?.address);
            setValue("inputTel", resultData?.tel);
            setValue("inputFax", resultData?.fax);
            setValue("clubOnly", resultData?.clubOnly);
            setValue("inputHomepage", resultData?.homepage);
            setValue("inputEmail", resultData?.email);
            setValue("inputSlogan", resultData?.slogan);
            setValue("inputEnglishName", resultData?.englishName);
            setValue("inputMeetingMonth", resultData?.meetingMonthDay);
            setValue("inputMeetingExe", resultData?.meetingExeDay);
            setValue("inputSamuwon", resultData?.samuwon);

            let clubType = {label:resultData?.clubType ? resultData?.clubType : "클럽타입 선택" , value: resultData?.clubType == "남성클럽" ? 1 : resultData?.clubType == "여성클럽" ? 2 : resultData?.clubType == "혼성클럽" ? 3 : 0}
            console.log(" clubType >> ", clubType);
            setSelectedClubType(clubType);

            if(resultData?.clubOnly == "clubOnly"){
                setIsClubOnly(true);
            }

            setSponsorClub(resultData?.sponsorClubName);
        });

        axios.get(comm.SERVER_URL + "/group/allclubs/").then(res => {
            setClubList(res.data);
        });

    }, []);

    // const VolunteerTransitionsModal = () => {
    //     const classes = useStyles()
    //     // const [openModal, setOpenModal] = useState(false);

    //     const handleClose = () => {
    //         setVolunteerModalOpen(false);
    //     };

    //     return (
    //         <div>
    //             <Modal
    //                 aria-labelledby="transition-modal-title"
    //                 aria-describedby="transition-modal-description"
    //                 className={classes.modal}
    //                 open={volunteerModalOpen}
    //                 onClose={handleClose}
    //                 closeAfterTransition
    //                 BackdropComponent={Backdrop}
    //                 BackdropProps={{
    //                     timeout: 500,
    //                 }}
    //             >
    //                 <Fade in={volunteerModalOpen}>
    //                     <div>
    //                         <AddMonthlyActivity fire={() => {
    //                             setVolunteerModalOpen(false)
    //                         }} club={props.club}/>
    //                     </div>
    //                 </Fade>
    //             </Modal>
    //         </div>
    //     );
    // }

    const onSubmit = (data: any) => {
        const { inputClubInitDate, inputOrgNo, inputInternationalNo,
            inputSponsorClub, inputAddress, inputTel, inputFax,
            inputHomepage, inputEmail, inputSlogan, clubOnly, inputEnglishName,inputMeetingMonth, inputMeetingExe, inputSamuwon} = getValues();

        //  update Club -- 클럽 정보는 오직 업데이트만 존재 ....
        let dtoObj = {
            id: props.club,
            startDay: inputClubInitDate,
            jiguNo: inputOrgNo,
            gukjeNo: inputInternationalNo,
            sponsorClubName: inputSponsorClub,
            address: inputAddress,
            email: inputEmail,
            tel: inputTel,
            fax: inputFax,
            homepage: inputHomepage,
            slogan: inputSlogan,
            clubOnly: clubOnly,
            belongTo: props.belonging.belongTo,
            jiyeokId: props.belonging.jiyeokId,
            jiguId: props.belonging.jiguId,
            clubType:selectedClubType.label,
            englishName:inputEnglishName,
            meetingMonthDay:inputMeetingMonth,
            meetingExeDay:inputMeetingExe,
            samuwon:inputSamuwon,
        }
        
        console.log("dtoObj >> ", dtoObj);

        axios.post(comm.SERVER_URL + "/group/club/", dtoObj).then((res) => {
            if (res.data.ok) {
                props.fire();
            }
        });
    };

    const handleClubChange = (ev: any) => {
        setSponsorClub(ev.target.value);
        // Fetch Data of designated club members 
    }

    const handleMonthlyActivity = () => {
        console.log(" **** ")
        
        setVolunteerModalOpen(true);
    }

    const onSelectClubType = (ev:any) => {
        setSelectedClubType(ev);
    }
    // const url = comm.SERVER_URL + 'member';
    return ( 
        <form onSubmit={handleSubmit(onSubmit)} className=" border-gray-800 border-2 bg-white h-144 overflow-auto">
            <div className="flex m-3 lg:m-8 flex-row">
                <div className="w-96 lg:w-256">
                    <div className="flex flex-row items-center justify-center">
                        <span className="text-xl">클럽 정보 입력(수정)</span>
                        <div className='h-8 w-40 ml-4  border-gray-200 border rounded-lg bg-blue-500 text-white flex items-center justify-center' onClick={handleMonthlyActivity}>월별 활동</div>
                    </div>
                    <div className="flex flex-row items-start mt-8">
                        <div className="flex flex-col w-32 lg:w-42">
                            <span>창립일</span>
                            <input type="date" name="inputClubInitDate" className="input mt-2 pl-0.5 pr-0.5" ref={register} />
                        </div>

                        <div className="flex flex-col w-28 ml-3 lg:w-48 lg:ml-6">
                            <span>지구 No</span>
                            <input type="text" name="inputOrgNo" className="input mt-2" ref={register}  />
                        </div>

                        <div className="flex flex-col w-28 ml-3 lg:w-48 lg:ml-6">
                            <span>국제 No</span>
                            <input type="text" name="inputInternationalNo" className="input mt-2" ref={register}  />
                        </div>
                    </div>
                    <div className="flex flex-row items-start mt-6">
                        <div className="flex flex-col w-40 lg:w-48">
                            <span>스폰서 클럽</span>
                            <div className="flex flex-row items-center mt-2">
                                {/* <select className="input w-40 mt-2" name="inputSponsorClub" onChange={handleClubChange} value={sponsorClub}>
                                    <option value={-1}>스폰서클럽 선택</option>
                                    {
                                        clubList.map((club: { id: number, name: string }) => {
                                            return (<option value={club.id}>{club.name}</option>)
                                        })
                                    }
                                </select> */}
                                <input type='text' name='inputSponsorClub' className='input w-40 mt-2' ref={register}></input>
                            </div>
                        </div>
                        <div className="flex flex-col">
                            <span>영문 클럽명</span>
                            <input type="text" name="inputEnglishName" className="input mt-4 w-36 lg:w-60" ref={register} />
                        </div>
                        <div className="flex flex-col ml-3 lg:ml-6">
                            <span>클럽 주소</span>
                            <div className="flex flex-row items-center mt-2">
                                <input type="text" name="inputAddress" ref={register} className="input mt-2 w-60 lg:w-120" ></input>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row items-center mt-6">
                        <div className="flex flex-col w-48">
                            <span>전화</span>
                            <input type="text" name="inputTel" ref={register} className="input mt-2 w-32 lg:w-48" ></input>
                        </div>
                        <div className="flex flex-col w-48 ml-3 lg:ml-6">
                            <span>팩스</span>
                            <input type="text" name="inputFax" className="input mt-2 w-32 lg:w-48" ref={register} />
                        </div>
                        <div className="flex flex-row w-48 ml-3 lg:ml-6 items-center mt-6">
                            <label className="flex flex-row items-center w-48">
                                <input type="checkbox" name="clubOnly" value={isClubOnly ? "clubOnly" : "false"} checked={isClubOnly ? true : false}  onClick={(ev) => { setIsClubOnly(!isClubOnly) }}  className="w-12 h-6 mr-1 " ref={register} />클럽 수첩 전용
                            </label>
                        </div>
                    </div>
                    <div className='flex flex-row items-center mt-6'>
                    <div className="flex flex-col">
                            <span>월례회 일자</span>
                            <input type="text" name="inputMeetingMonth" className="input mt-2 w-36 lg:w-60" ref={register} />
                        </div>
                        <div className="flex flex-col ml-3 lg:ml-6">
                            <span>이사회 일자</span>
                            <input type="text" name="inputMeetingExe" className="input mt-2 w-36 lg:w-60" ref={register} />
                        </div>
                        <div className="flex flex-col ml-3 lg:ml-6">
                            <span>사무직원 정보</span>
                            <input type="text" name="inputSamuwon" className="input mt-2 w-36 lg:w-72" ref={register} placeholder='이름, 전화번호 ex)홍길동,01099001100' />
                        </div>
                    </div>
                    <div className="flex flex-row items-center mt-6">
                        <div className="flex flex-col ">
                            <span>홈페이지</span>
                            <input type="text" name="inputHomepage" className="input mt-2 w-40 lg:w-60" ref={register} />
                        </div>
                        <div className="flex flex-col">
                            <span>이메일</span>
                            <input type="text" name="inputEmail" className="input mt-2 w-36 lg:w-60" ref={register} />
                        </div>
                        <div className="flex flex-col ml-3 lg:ml-6">
                            <span className='mt-2'>클럽 성별 타입</span>
                            <Select
                                value={selectedClubType}
                                className="mt-4 w-28 lg:w-32 mb-4"
                                name="inputClubType"
                                onChange={onSelectClubType}
                                options={clubGenderTypes} ref={register} />
                        </div>
                    </div>
    
 
                    {/* <div className="flex flex-row items-start mt-6">
                        <div className="flex flex-col">
                            <span>슬로건</span>
                            <input type="text" name="inputSlogan" ref={register} className="input mt-2 w-144" ></input>
                        </div>ß
                    </div> */}

                </div>
            </div>
            <div className="flex flex-row justify-center items-center h-32">
                <button type="submit" className="mr-4 lg:mr-8 rounded-lg lg:rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-36 lg:w-48 h-12 lg:h-16 hover:bg-blue-200">클럽 정보 수정(입력)</button>
                <button type="button" className="ml-4 lg:ml-8 rounded-lg lg:rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-36 lg:w-48 h-12 lg:h-16 hover:bg-red-200" onClick={props.fire}>취소</button>
            </div>
            {/* {VolunteerTransitionsModal()} */}
        </form>
    )
}

export default AddClubInfo;