import React, { useState, useRef, useEffect, useCallback} from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import tempVal from '../../temp';
import { useHistory, useLocation } from 'react-router-dom';
import { clubType } from './clubList';
import {getMemberImg, getMemberImgWithOption, getMobilePhoneFormText, isNFD} from '../common/util';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import backgroundLogoV2 from '../../images/lionsSymbol206.png';
import ImgCall from '../../images/icon-call.png';
import ImgMoney from '../../images/icon_money.png';
import useWindowEventListener from "@rooks/use-window-event-listener"
import TagManager from 'react-gtm-module';

interface MobileMainProps {
    clubId: number,
    id: number,
    clubOnly?:boolean,
}

export interface userDto {
    id?: number;

    positionJigu?: string;
    positionJiguVal?: number;
    positionClubVal?:number;
    positionJiyeok?: string;
    positionJiyeokVal?: number;
    positionFreeClub?: string;
    positionFreeJigu?: string;
    positionFreeJiyeok?: string;
    phoneNumHome?: string;
    phoneNumWork?: string;
    email?: string;
    belongTo?: string;
    imgName?: string;
    sponId?: number;
    supportCnt?: number;

    slogan?:string;
    positionLclub?:number; 
    formerJiguChongjae?: string;

    belongToJidae?:number;
    belongToJiyeok?:number;
    belongToJigu?:number;
    clubName?:string;
    reportingMonth?:string;

    formerInsa?:string;
    formerEdu?:string;
    formerJiguChongjang?:string;

    // ================================= //  필수 데이터
    positionClub:string;
    belongToClub:number;
    name:string;
    startDay:string;
    job:string;
    address?:string;
    mobileNum:string;
    englishName?:string;
    memberNo:string;
    memberType?:string;
    memberTypeArr:Array<string>;
    gender:string;
    sponsorName:string;
    birthYear:string;
    quitReason?:string;
    dropout?:string;
    zipCode:string;
    approvalDay:string;
    // ============================= //

}

export interface QuitUserDto{
    name:string;
    memberNo:string;
    quitReason:string;
    dropout:string;
    approvalDay:string
    reportingMonth?:string;
    belongToClub?:number;
    belongTo?:number;                                                                                                                                                                                                                                                                                     
    belongToJidae?:number;
    belongToJiyeok?:number;
    belongToJigu?:number;
    clubName?:string;
    id?:number;
}

const MemberList: React.FC<MobileMainProps> = (props) => {
    const [searchKeyword, setSearchKeyword] = useState("");
    const [searchedResult, setSearchedResult] = useState([]);
    const [userList, setUserList] = useState([]);
    const [clubInfo, setClubInfo] = useState<any>();
    const location = useLocation<MobileMainProps>();
    const history = useHistory();
    const {pathname} = useLocation<any>();

    // console.log(" props >>>>> ", props);

    const hanldeSearhKeyword = (ev: any) => {
        setSearchKeyword(ev.event.value);
    };

    useEffect(() => {
        getUserList(location.state.clubId);
        getClubInfo(location.state.clubId);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
      
   
    }, []);

    const onScroll = useCallback(() => {
        requestAnimationFrame(() => {
            const { pageXOffset, pageYOffset} = window;
            if(pageYOffset !== 0){
                tempVal.yOffset = pageYOffset;
            }
        });

    }, []);
    
    useWindowEventListener('scroll', onScroll);

    const getUserList = (clubId: number) => {
        axios.get(comm.SERVER_URL + "/member/clubMobile", {
            params: {
                id: clubId
            }
        }).then(res => {
            setUserList(res.data);
            console.log("res data >> ", res.data);
            let userInfoData = [];
            res.data.forEach((each : any) => {
                userInfoData.push({
                    id:each.id,
                    name:each.name,
                    startDay:each.startDay,
                    job:each.job
                });
            });

            // TagManager.dataLayer({
            //     dataLayer:{
            //         event:'user-info',
                    
            //     dataLayerName:'UserInfo'
            // });


            if(history.action === "POP"){
                window.scrollTo(0,tempVal.yOffset);
            }
        })
    };

    const getClubInfo = (clubId: number) => {
        axios.get(comm.SERVER_URL + "/group/clubInfo", {
            params: {
                id: clubId
            }
        }).then(res => {
            setClubInfo(res.data);
        })
    };

    const handleSearch = (ev: any) => {
        // do search Login
        axios.get(comm.SERVER_URL + "/search", {
            params: {
                searchKeyword: searchKeyword
            }
        }).then(res => {
            
        });
    };

    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }

    const getListItem = (listData: Array<userDto>) => {
        if (listData.length === 0) {
            return (<div className="flex flex-col justify-center items-center mt-8"><p className="text-2xl">회원이 없습니다.</p></div>)
        } else {
            return listData.map(each => {
                let id = each.id;
                let nameNPosition = each.name + " / " + each.positionClub;
                let startDay = each.startDay;
                let job = each.job;
                let address = each.address;
                let mobileNum = each.mobileNum;
                let sponsorId = each.sponId;
                let supportCnt = each.supportCnt;
                // mobileNum = "H.P) " + mobileNum.substr(0, 3) + "-" + mobileNum.substr(4, 4) + "-" + mobileNum.substr(7, 4);
                // let imgUrl = each.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${each.imgName}`: logo;
                
                let imgUrl = getMemberImg(each);
                // if(each.imgName){
                //     if(isNFD(each.imgName)){
                //         imgUrl = getMemberImgWithOption(each,'NFC');
                //     }else{
                //         imgUrl = getMemberImgWithOption(each,'NFD');
                //     }
                // }else{
                //     imgUrl = getMemberImg(each);
                // }
                

                return (
                    <div onClick={() => {
                        history.push("/memberDetail", { memberId: id, sponsorId: sponsorId });
                    }}>
                        <div className={each.positionClubVal === 1 ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">회장</span></div>
                        <div className={each.positionClubVal === 1 ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                            <img src={imgUrl} className="w-16 h-20 rounded-3xl object-fill ml-4 mt-1" />
                            <div className="flex flex-col ml-4">
                                <div className="flex flex-row items-center">
                                    <span className="ex-name-text">{each.name}</span>
                                    {
                                        supportCnt ? (<div className="flex flex-row items-center"><img src={ImgMoney} className="img-money" />
                                            <span className="text-money">찬조</span></div>) : null
                                    }
                                </div>

                                <span className="ex-position-text">{each.positionClub} {each.positionFreeClub && each.positionClub ? " / " + each.positionFreeClub : each.positionFreeClub ? each.positionFreeClub : ""}</span>
                                <div className="ex-tel" onClick={(ev) => { handleCall(each?.mobileNum) }}>
                                    <img src={ImgCall} className="ex-tel-img" />
                                    <span className="ex-tel-text">{getMobilePhoneFormText(each.mobileNum)}</span>
                                </div>
                            </div>
                            <div className="sponsor-blank"></div>
                            {sponsorId ?
                                <div className="sponsor-wrap">
                                    <span className="sponsor-text">협찬</span>
                                </div> : null
                            }
                        </div>
                    </div>
                )
            })
        }

    }
 
    return (
      
        <div className="flex flex-col">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title={clubInfo?.clubInfo.name} clubOnly={location.state.clubOnly} />
            </div>
            <div className="flex-1 mt-14 mb-16">
                {/* <div className="text-center text-3xl font-bold mt-4 mb-4">{clubInfo?.name}</div> */}
                <div id="clubInfo" className="member-List-club-des" >
                    <span className="memberlist-club-text">창립일: {clubInfo?.clubInfo.startDay}</span>
                    <span className="memberlist-club-text">영문이름: {clubInfo?.clubInfo.englishName}</span>
                    <div className="memberlist-club-text">지구No: {clubInfo?.clubInfo.jiguNo}</div>
                    <div className="memberlist-club-text">국제No: {clubInfo?.clubInfo.gukjeNo}</div>
                    <div className="memberlist-club-text">스폰서클럽: {clubInfo?.sponsorClubName}</div>
                    <div className="memberlist-club-text overflow-visible">클럽주소: <span className={ window.innerWidth <= 320 ? 'memberlist-club-text-narrow' : 'memberlist-club-text'}>{clubInfo?.clubInfo.address}</span></div>
                    <div className="memberlist-club-text">전화: {clubInfo?.clubInfo.tel}</div>
                    <div className="memberlist-club-text">FAX: {clubInfo?.clubInfo.fax}</div>
                    <div className="memberlist-club-text">홈페이지: {clubInfo?.clubInfo.homepage}</div>
                    <div className="memberlist-club-text">이메일: {clubInfo?.clubInfo.email}</div>
                    <div className="memberlist-club-text">월례회일: {clubInfo?.clubInfo.meetingMonthDay}</div>
                    <div className="memberlist-club-text">이사회일: {clubInfo?.clubInfo.meetingExeDay}</div>
                    <div className="memberlist-club-text">사무직원: {clubInfo?.clubInfo.samuwon}</div>
                    <img src={backgroundLogoV2} className="back-logo-2" />
                </div>
                <div id="list" className="flex flex-col mt-6">{getListItem(userList)}</div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default MemberList;

