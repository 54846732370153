import React, { useState, useEffect, createContext} from 'react';

export interface groupInfo {
    memberId:number;
    clubId:number;
    jidaeId:number;
    jiyeokId:number;
    jiguId:number;
    
    jiguName: string; 
    jiyeokName: string;
    clubName: string;
    superUser:boolean;

}

const GroupContext = createContext({
    memberId:-1,
    clubId:-1,
    jidaeId:-1,
    jiyeokId:-1,
    jiguId:-1,
    jiguName:"",
    jiyeokName:"",
    clubName:"",
    superUser:false,

})

export default GroupContext;
