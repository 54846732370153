import React, { useContext } from 'react';
import { useHistory} from "react-router-dom";
import GroupContext from '../../context/groupContext';
import clubImg from '../../images/clubImg.png';
import jiguImg from '../../images/jiguImg.png';
import jiyeokImg from '../../images/jiyeokImg.png';
import lionsImg from '../../images/lionsImg.png';
import messageImg from '../../images/messageImg.png';
import liconClubImg from '../../images/lions_lions_tr.png';
// import liconClubImg2 from '../../images/lions_lionsclub2.png';
import './common.css'; 

interface MobileFooterProps {
    clubId?: string,
    jiyeokId?: string,
    jiguId?: string,  
};

const MobileFooter: React.FC<MobileFooterProps> = (props: MobileFooterProps) => {
    let history = useHistory();
    
    const groupValue = useContext(GroupContext);
    const extraUrl = groupValue.memberId+"&"+groupValue.clubId+"&"+groupValue.jidaeId+"&"+groupValue.jiyeokId+"&"+groupValue.jiguId;

    const is354B10Jiyeok = groupValue.jiyeokId == 2 ? true : false;

    const goHome = () => {
        console.log("go Home !! > ");
        history.push("/clubList/"+extraUrl, { clubId: props.clubId });
    };

    const goJigu = () => {
        history.push("/jiguMemberList", { clubId: props.clubId });
    };

    const goJieyok = () => {
        history.push("/jiyeokMemberList", { clubId: props.clubId });
    };

    const goLclub = () => {
        // history.push("/noticeList", { clubId: props.clubId });
        history.push("/lclubmemberList");
    };

    const goAboutLions = () => {
        history.push("/aboutLions", { clubId: props.clubId });
    };

    return (
        <div id="footer" className="flex flex-row items-center justify-around h-14 bg-lions-blue">
            <div className="flex flex-col items-center" onClick={goHome} id="gohome">
                <img src={clubImg} alt="club" className="clubImg"/>
                <span className="text-white text-base">클럽</span>
            </div>
            <div className="flex flex-col items-center" onClick={goJigu} id="gojigu" >
                <img src={jiguImg} alt="jigu" className="clubImg"/>
                <span className="text-white">지구</span>
            </div>
            <div className="flex flex-col items-center" onClick={goJieyok} id="gojiyeok">
                <img src={jiyeokImg} alt="jiyeok" className="clubImg"/>
                <span className="text-white">지역</span>
            </div>
            <div className="flex flex-col items-center" onClick={goAboutLions} id="golions">
                <img src={lionsImg} alt="lions" className="clubImg"/>
                <span className="text-white">라이온스</span>
            </div>
            <div className={is354B10Jiyeok ? "flex flex-col items-center" : "hidden"} onClick={goLclub} id="golclub">
                <img src={liconClubImg} alt="message" className="clubImg_lions"/>
                <span className="text-white">사자회</span>
            </div>
        </div>
    );  


}

export default MobileFooter;