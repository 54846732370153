import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation} from "react-router-dom";
import GroupContext from '../../context/groupContext';
import jiguExeImg from '../../images/jigu_exe.png';
import chongjaedanImg from '../../images/chongjaedan.png';
import jiyeokImg from '../../images/jiyeokImg.png';
import lionsImg from '../../images/lionsImg.png';
import messageImg from '../../images/messageImg.png';
import liconClubImg from '../../images/lions_lions_tr.png';
// import liconClubImg2 from '../../images/lions_lionsclub2.png';
import './common.css'; 

interface MobileFooterProps {
    clubId?: string,
    jiyeokId?: string,
    jiguId?: string,
    position?: number,  
};

const ExecutiveFooter: React.FC<MobileFooterProps> = (props: MobileFooterProps) => {
    let history = useHistory();

    const clickRef1 = useRef<HTMLDivElement | null>(null);
    const clickRef2 = useRef<HTMLDivElement | null>(null);
    
    const location: any = useLocation();

    const groupValue = useContext(GroupContext);
    const extraUrl = groupValue.memberId+"&"+groupValue.clubId+"&"+groupValue.jidaeId+"&"+groupValue.jiyeokId+"&"+groupValue.jiguId;

    const is354B10Jiyeok = groupValue.jiguId== 3 ? true : false;
    console.log("jiguId >> ", groupValue.jiguId);
    console.log("is354B10Jiyeok >> ", is354B10Jiyeok);
    
    // const [clickedFooter, setClickedFooter] = useState(0);

    console.log(' props.position in footer >>>>> ', props.position);

    useEffect(()=>{
        if(props.position && props.position < 6 ){
            clickRef1.current?.scrollIntoView({ behavior: 'smooth', inline:'center'});
        }else if(props.position && props.position > 6){
            clickRef2.current?.scrollIntoView({ behavior: 'smooth', inline:'end'});
        }
    },[])

    const goJibhang = () => {
        history.push("/executiveList/"+extraUrl, { title:"총재단", footerPosition: 0 });

    };

    const goJiguExecutives = () => {
        history.push("/jiyeokExeSelectScreen", { title:"지구임원", footerPosition: 1 });

    };

    const goLions = () => {
        history.push("/aboutLions",{id: groupValue.jiguId} );

    };

    
    return (
        <div className='overflow-x-auto w-100%'>
            <div id="footer" className="flex flex-row items-center justify-around h-14 bg-lions-blue overflow-x-auto">
                <div className="flex flex-col items-center" onClick={goJibhang} id="goJibhang" ref={clickRef1}>
                    <img src={chongjaedanImg} alt="club" className="clubImg"/>
                    <span className="text-white text-sm">총재단</span>
                </div>
                <div className="flex flex-col items-center" onClick={goJiguExecutives} id="goJiguExecutives" >
                    <img src={jiguExeImg} alt="jigu" className="clubImg"/>
                    <span className="text-white text-sm">지구임원</span>
                </div>
                <div className="flex flex-col items-center" onClick={goLions} id="goLions">
                    <img src={lionsImg} alt="lions" className="clubImg"/>
                    <span className="text-white text-sm">라이온스</span>
                </div>
            </div>
        </div>
    );  


}

export default ExecutiveFooter;