import React, { useRef, useState, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import { Img } from 'react-image'
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
import { Location } from 'history';
import { useHistory, useLocation } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AddSponsor from './addSponsor';
import AddSupport from './addSupport';
import CloseIcon from '@material-ui/icons/Close';
import Select from "react-select";
import { formerChairmanOption, clubOption, jiguOption, jiyeokOption, formerJiguChongjaeOption, memberOptionTypes, gender } from '../admin/selectItems';
import imageCompression from 'browser-image-compression';
import { ChildProps, selectDataType } from '../common/dto';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
        },
        modalMedium: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'scroll',
            maxHeight: '450px',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }),
);

const AddMember: React.FC<ChildProps> = (props: ChildProps) => {
    const { register, handleSubmit, watch, errors, getValues, setValue } = useForm();
    const [memberImg, setMemberImg] = useState(logo);
    const [mImg, setmImg] = useState("");
    const [addSponsorModalOpen, setAddSponsorModalOpen] = useState(false);
    const [addSupportModalOpen, setAddSupportModalOpen] = useState(false);

    const [checkedLifelongMember, setCheckedLifelongMember] = useState(false);
    const [checkedlclubMember, setCheckedlclubMember] = useState(false);
    const [checkSendSMS, setCheckSendSMS] = useState(false);
    const [initDeviceId, setInitDeviceId] = useState(false);

    const [checkedChongjae, setCheckedChongjae] = useState(false);
    const [checkedBuChongjae, setCheckedBuChongjae] = useState(false);

    const [lclubPositionIdx, setLClubPositionIdx] = useState(0);

    const [isClubChairmanSlogan, setIsClubChairmanSlogan] = useState(false);
    const [isChongJaeSlogan, setIsChongJaeSlogan] = useState(false);
    const [isBuChongJaeSlogan, setIsBuChongJaeSlogan] = useState(false);

    const [selectedClubPosition, setSelectedClubPosition] = useState(0);
    const [selectedJiyeokPosition, setSelectedJiyeokPosition] = useState(0);
    const [selectedJiguPosition, setSelectedJiguPosition] = useState(0);

    // const [selectedMemberType, setSelectedMemberType] = useState<selectDataType>({ value: 0, label: "회원 타입을 선택해 주세요" });
    const [selectedMemberTypeArr, setSelectedMemberTypeArr] = useState<Array<string>>([]);
    const [selectedGender, setSelectedGender] = useState<selectDataType>({ value: 0, label: '성별 선택' });

    const [fchMan, setFchMan] = useState<Array<number>>([]);
    const [selectedFchMan, setSelectedFchMan] = useState<Array<selectDataType>>([]);

    const [fjiguChMan, setFjiguChMan] = useState<Array<number>>([]);
    const [selectedFjiguChMan, setSelectedFjiguChMan] = useState<Array<selectDataType>>([]);

    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    
    const [selectedGFCheckBox, setSelectedGFCheckBox] = useState("");
    const [selectedTRCheckBox, setSelectedTRCheckBox] = useState("");

    const getJiyeokPositionData = (positionVal: number) => {
        return jiyeokOption.filter(each => {
            return each.value === positionVal;
        })[0];
    }

    const getJiguPositionData = (positionVal: number) => {
        return jiguOption.filter(each => {
            return each.value === positionVal;
        })[0];
    }

    const getMemberTypeData = (positionVal: number) => {
        return memberOptionTypes.filter(each => {
            return each.value === positionVal;
        })[0];
    }

    const getClubPositionData = (positionVal: number) => {
        let result = clubOption.filter(each => {
            return each.value === positionVal;
        })[0];

        if (result) {
            return result;
        } else {
            return { value: -1, label: "" };
        }
    }

    // useEffect(()=>{
    //     console.log("selectedMemberTypeArr >> ", selectedMemberTypeArr);
    //     console.log(selectedMemberTypeArr.includes("일반회원"))
    // },[selectedMemberTypeArr])

    useEffect(() => {
        console.log("window size width >> ", windowSize.current[0])
        console.log("window size height >> ", windowSize.current[1])
        // props.memberId
        axios.get(comm.SERVER_URL + "/member/", {
            params: {
                id: props.memberId
            }
        }).then(res => {
            console.log(" res.data >> ", res.data);
            if(!res.data){
                setSelectedMemberTypeArr([]);
            }else{
                setSelectedMemberTypeArr(res.data.memberTypeArr);
            }
            setValue("inputName", res.data.name);
            setValue("inputPositionClub", res.data.positionClubVal);
            setValue("inputPositionJigu", res.data.positionJiguVal);
            setValue("inputPositionJiyeok", res.data.positionJiyeokVal);

            
            console.log(" formerChairman >> ", res.data.formerChairman);

            let chairmanVal: Array<number> = res.data.formerChairman;
            let fchValues: Array<selectDataType> = [];

            if (chairmanVal) {
                chairmanVal.forEach(each => {
                    formerChairmanOption.forEach(formerChairman => {
                        if (formerChairman.value === each) {
                            fchValues.push(formerChairman);
                        }
                    });
                });
            }

            setFchMan(chairmanVal);
            setSelectedFchMan(fchValues);


            let jiguChongJaeVal: Array<number> = res.data.formerJiguChongjae;
            let fjiguChongJaeValues: Array<selectDataType> = [];

            if (jiguChongJaeVal) {
                jiguChongJaeVal.forEach(each => {
                    formerJiguChongjaeOption.forEach(formerChairman => {
                        if (formerChairman.value === each) {
                            fjiguChongJaeValues.push(formerChairman);
                        }
                    });
                });
            }

            setFjiguChMan(jiguChongJaeVal);
            setSelectedFjiguChMan(fjiguChongJaeValues);

            if (res.data.positionClub?.includes("평생 회원")) {
                setCheckedLifelongMember(true);
            }

            if (res.data.positionJigu?.includes("역대 총재")) {
                setCheckedChongjae(true);
            }

            if (res.data.positionJiyeok?.includes("역대 부총재")) {
                setCheckedBuChongjae(true);
            }


            if (res.data.positionClubVal == 1) {
                setIsClubChairmanSlogan(true);
            }

            if (res.data.positionJiguVal == 1 || res.data.positionJiguVal == 2) {
                setIsChongJaeSlogan(true);
            }

            if (res.data.positionJiyeokVal == 1 || res.data.positionJiyeokVal == 2) {
                setIsBuChongJaeSlogan(true);
            }

            setValue("inputPositionFreeClub", res.data.positionFreeClub);
            setValue("inputPositionFreeJigu", res.data.positionFreeJigu);
            setValue("inputPositionFreeJiyoek", res.data.positionFreeJiyeok);

            setValue("inputMemberDate", res.data.startDay);
            setValue("inputMemberNo", res.data.memberNo);
            setValue("inputJob", res.data.job);

            setValue("inputAddress", res.data.address);
            setValue("inputMobile", res.data.mobileNum);
            setValue("inputHome", res.data.phoneNumHome);
            setValue("inputBusiness", res.data.phoneNumWork);
            setValue("inputNameEnglish", res.data.englishName);
            setValue("inputEmail", res.data.email);

            // setValue("inputGender", res.data.gender)
            // setValue("inputMemberType", res.data.memberType)
            let gender = { value: 0, label: "성별 선택" }
            if (res.data.gender == "남자") {
                gender.value = 1;
                gender.label = "남자"
            } else if (res.data.gender == "여자") {
                gender.value = 2;
                gender.label = "여자"
            }


            setSelectedGender(gender);

            if (res.data.positionLclub) {
                setCheckedlclubMember(true);
                setValue("inputPositionLclub", res.data.positionLclub);
            }

            if (res.data.positionClubVal == 1 && res.data.slogan) {
                setValue("inputClubChairmanSlogan", res.data.slogan);
            }

            if ((res.data.positionJiguVal == 1 || res.data.positionJiguVal == 2) && res.data.slogan) {
                setValue("inputChongjaeSlogan", res.data.slogan);
            }

            if ((res.data.positionJiyeokVal == 1 || res.data.positionJiyeokVal == 2) && res.data.slogan) {
                setValue("inputBuChongjaeSlogan", res.data.slogan);
            }

            setSelectedClubPosition(res.data.positionClubVal ? res.data.positionClubVal : 0);
            setSelectedJiguPosition(res.data.positionJiguVal ? res.data.positionJiguVal : 0);
            setSelectedJiyeokPosition(res.data.positionJiyeokVal ? res.data.positionJiyeokVal : 0);


            if (props.memberId !== -1) {
                const imgUrl = `https://clublions.s3.ap-northeast-2.amazonaws.com/${res.data.imgName}`;
                if (res.data.imgName) {
                    setMemberImg(imgUrl);
                    setmImg(res.data.imgName);
                } else {
                    setMemberImg(logo);
                    setmImg(logo);
                }
            }

        })

    }, []);

    const onSelectionClubPositionChange = (ev: any) => {
        setSelectedClubPosition(ev.value);
        if (ev.value == 1) {
            setIsClubChairmanSlogan(true);
        } else {
            setIsClubChairmanSlogan(false);
        }
    };

    const onSelectionLClubPositionChange = (ev: any) => {
        setLClubPositionIdx(ev.target.value);
    };

    const onSelectionJiguPositionChange = (ev: any) => {
        setSelectedJiguPosition(ev.value);
        if (ev.value == 1 || ev.value == 2) {
            setIsChongJaeSlogan(true);
        } else {
            setIsChongJaeSlogan(false);
        }
    };

    const onSelectionJiyeokPositionChange = (ev: any) => {
        setSelectedJiyeokPosition(ev.value);
        console.log(" ev.val >>>>>> ", ev.val);
        if (ev.value == 1 || ev.value == 2) {
            setIsBuChongJaeSlogan(true);
        } else {
            setIsBuChongJaeSlogan(false);
        }
    };

    const onSelectionFormerChairmanChange = (ev: any) => {
        let values = [];
        for (let i = 0; i < ev.length; i++) {
            values.push(ev[i].value);
        }
        setFchMan(values);
        setSelectedFchMan(ev);
    };

    // const onSelectionMemberType = (ev: any) => {
    //     console.log("onSelectionMemberType >>> ", ev);
    //     setSelectedMemberType(ev);
    // };

    const onSelectionFormerJiguChongjaeChange = (ev: any) => {
        let values = [];
        for (let i = 0; i < ev.length; i++) {
            values.push(ev[i].value);
        }
        setFjiguChMan(values);
        setSelectedFjiguChMan(ev);
    };

    const getMinChiarman = () => {
        return Math.min.apply(null, fchMan); // 역대 회장 중 작은 값(= 우선 순위가 높은 값)
    };

    const getTextChairman = () => {
        let cMan = "";
        fchMan.forEach((chairman, index) => {
            let modifiedChairman = chairman - 30;
            if (index === fchMan.length - 1) {
                cMan = cMan + modifiedChairman + "대 회장";
            } else {
                cMan = cMan + modifiedChairman + ",";
            }
        });
        return cMan;
    }

    const getClubPositionWithCase = () => {
        let resultClubPosition = "";
        let originalClubPosition = getClubPositionData(selectedClubPosition);
        if (originalClubPosition && originalClubPosition.value > 0) { // 클럽 직책이 있고,
            resultClubPosition = originalClubPosition.label;
            if (fchMan?.length > 0) {     // 역대 회장 체크 되어 있으면,
                resultClubPosition = resultClubPosition + "/ " + getTextChairman();
            }

            if (checkedLifelongMember) {     // 평생 회원 체크 되어 있으면
                resultClubPosition = resultClubPosition + "/ " + "평생 회원";
            }
        } else {                  // 클럽 직책이 없고,
            if (fchMan?.length > 0) {     // 역대 회장 체크 되어 있으면,
                resultClubPosition = getTextChairman();

                if (checkedLifelongMember) {     // 평생 회원 체크 되어 있으면
                    resultClubPosition = resultClubPosition + "/ " + "평생 회원";
                }
            } else {
                if (checkedLifelongMember) {     // 평생 회원 체크 되어 있으면
                    resultClubPosition = "평생 회원";
                }
            }
        }
        return resultClubPosition;

    }

    const getClubPositionValWithCase = () => {
        let resultClubPositionVal = null;
        if (selectedClubPosition > 0) { // 클럽 직책이 있고,
            resultClubPositionVal = selectedClubPosition;
            if (resultClubPositionVal === 121 || resultClubPositionVal === 122) {     // 이사나, 회원이면
                if (checkedLifelongMember) {             // 평생 회원 이면
                    resultClubPositionVal = 120;
                }

                if (fchMan?.length > 0) {              // 역대 회장 이면
                    resultClubPositionVal = getMinChiarman();
                }
            }
        } else {                 // 클럽 직책이 없고,
            if (checkedLifelongMember) {     // 평생 회원 체크 되어 있으면
                resultClubPositionVal = 120;
            }
            if (fchMan?.length > 0) {     // 역대 회장 이면
                resultClubPositionVal = getMinChiarman();
            }
        }
        return resultClubPositionVal;
    }

    const getJiguPositionWithCase = () => {
        let resultVal = "";
        let jiguPosition = getJiguPositionData(selectedJiguPosition)
        console.log(" jiguPosition >>>> ", jiguPosition);

        if (jiguPosition && jiguPosition.value > 0) {
            resultVal = jiguPosition.label;
            if (fjiguChMan?.length > 0) {
                let former = "";
                fjiguChMan.forEach((each: number, index: number) => {
                    let gisu = each - 30;
                    if (index != fjiguChMan.length - 1) {
                        former = former + gisu + "대, ";
                    } else {
                        former = former + gisu + "대 지구 총재";
                    }
                });
                resultVal = resultVal + "/ " + former;
            }
        } else {
            if (fjiguChMan?.length > 0) {
                let former = "";
                fjiguChMan.forEach((each: number, index: number) => {
                    let gisu = each - 30;
                    if (index != fjiguChMan.length - 1) {
                        former = former + gisu + "대, ";
                    } else {
                        former = former + gisu + "대 지구 총재";
                    }
                });
                resultVal = former;
            }
        }

        return resultVal;
    }

    const getJiguPositionValWithCase = () => {
        let resultVal = 0;
        if (selectedJiguPosition > 0) {
            resultVal = selectedJiguPosition;
        } else {
            if (fjiguChMan?.length > 0) {
                resultVal = 5;   // 역대 총재 5번
            }
        }
        return resultVal;
    }

    const getJiyeokPositionWithCase = () => {
        let resultVal = "";
        let jiyeokPosition = getJiyeokPositionData(selectedJiyeokPosition);
        if (jiyeokPosition && jiyeokPosition.value > 0) {
            resultVal = jiyeokPosition.label;
            if (checkedBuChongjae) {
                resultVal = resultVal + "/ " + "역대 부총재";
            }
        } else {
            if (checkedBuChongjae) {
                resultVal = "역대 부총재";
            }
        }
        return resultVal;
    }

    const getJiyeokPositionValWithCase = () => {
        let resultVal = 0;
        if (selectedJiyeokPosition > 0) {
            resultVal = selectedJiyeokPosition;
        } else {
            if (checkedBuChongjae) {
                resultVal = 100;   // 역대 부총재 100번
            }
        }
        return resultVal;
    }

    const onSubmit = (data: any) => {
        const { inputPic, inputName, inputPositionClub, inputPositionJigu,
            inputPositionJiyeok, inputPositionFreeClub, inputPositionFreeJigu,
            inputPositionFreeJiyoek, inputMemberDate,
            inputJob, inputAddress, inputMobile, inputHome, inputPositionLclub,
            inputBusiness, inputEmail, inputNameEnglish, inputMemberNo, inputChongjaeSlogan, inputBuChongjaeSlogan, inputClubChairmanSlogan, inputFormerChairmans, inputGender, inputMemberType } = getValues();

            let imgName = inputPic[0]?.lastModified + inputPic[0]?.name;
            let dtoObj = {
                name: inputName,
                // positionClub: inputPositionClub,
                positionClub: getClubPositionWithCase(),
                positionClubVal: getClubPositionValWithCase(),

                positionJigu: getJiguPositionWithCase(),
                positionJiguVal: getJiguPositionValWithCase(),

                positionJiyeok: getJiyeokPositionWithCase(),
                positionJiyeokVal: getJiyeokPositionValWithCase(),

                positionFreeClub: inputPositionFreeClub,
                positionFreeJigu: inputPositionFreeJigu,
                positionFreeJiyeok: inputPositionFreeJiyoek,

                positionLclub: lclubPositionIdx,

                startDay: inputMemberDate,
                job: inputJob,
                address: inputAddress,
                mobileNum: inputMobile,
                phoneNumHome: inputHome.replace(/-/gi, ""),
                phoneNumWork: inputBusiness.replace(/-/gi, ""),
                email: inputEmail,
                englishName: inputNameEnglish,
                memberNo: inputMemberNo,
                belongTo: props.club,
                belongToClub: props.club,

                belongToJigu: props.extraData?.jigu,
                belongToJiyeok: props.extraData?.jiyeok,
                belongToJidae: props.extraData?.jidae,
                imgName: imgName,
                clubName: props.clubName,
                slogan: inputChongjaeSlogan ? inputChongjaeSlogan : inputBuChongjaeSlogan ? inputBuChongjaeSlogan : inputClubChairmanSlogan ? inputClubChairmanSlogan : "",
                formerChairman: fchMan,
                formerJiguChongjae: fjiguChMan,
                // memberType: selectedMemberType.label,
                memberTypeArr:selectedMemberTypeArr,
                gender: selectedGender.label,
                initDeviceId:false,
                checkSendSMS:false,
            }

        let img_name_detail = dtoObj.belongToJigu +"_" + dtoObj.belongToJiyeok + "_" + dtoObj.belongToClub + "_" + dtoObj.clubName+ "_" +dtoObj.name+"_"+inputPic[0]?.lastModified;   
        dtoObj.imgName = img_name_detail
        if (props.memberId === -1) {
            //  AddMember

            if (!getClubPositionWithCase()) {
                alert("클럽 직책(또는 역대회장, 평생회원)을 입력하세요!");
                return;
            }
            axios.post(comm.SERVER_URL + "/member", dtoObj).then((res) => {
                if (res.data.ok) {
                    if (inputPic && inputPic.length > 0) {
                        uploadProfilePic(inputPic, img_name_detail);
                    } else {
                        props.fire();
                    }
                    // ==== 뿌리오 문자 전송 로직 ===== //
                    // sendSMSByBBurio();
                    if (dtoObj.positionClubVal == 1 && inputClubChairmanSlogan != "") {
                        axios.post(comm.SERVER_URL + "/group/club/", { id: props.club, slogan: inputClubChairmanSlogan }).then(res => {
                            console.log(" res >>> ", res.data);
                        });
                    };

                } else if (res.data.error === "The user already exists") {
                    alert("이미 등록된 회원입니다.");
                }
            });

        } else {
            console.log(" UPDATE MEMBER !! ");

            //  updateMember
            let imgName = "";
            if (inputPic.length > 0) {
                // imgName = inputPic[0]?.lastModified + inputPic[0]?.name;
                imgName = img_name_detail;
            } else if (memberImg !== logo) {
                imgName = mImg;
            }

            console.log(" props.memberId >> ", props.memberId);

            let updateDtoObj = {...dtoObj, id:props.memberId};
            updateDtoObj.imgName = imgName;
            updateDtoObj.initDeviceId = initDeviceId;
            updateDtoObj.checkSendSMS = checkSendSMS;

            console.log(" update dto >> ", updateDtoObj);
            axios.put(comm.SERVER_URL + "/member", updateDtoObj).then((res) => {
                if (res.data.ok) {
                    if (inputPic && inputPic.length > 0) {
                        uploadProfilePic(inputPic, imgName);
                    } else {
                        props.fire();
                    }

                    if (dtoObj.positionClubVal == 1 && inputClubChairmanSlogan != "") {
                        axios.post(comm.SERVER_URL + "/group/club/", { id: props.club, slogan: inputClubChairmanSlogan }).then(res => {
                            console.log(" res >>> ", res.data);
                        });
                    }
                }
            });
        }
    };

    const uploadProfilePic = async (inputPic: Array<any>, imgName: string) => {
        const actualFile = inputPic[0];

        const options = {
            maxSizeMB: 1.99,
        };

        try {
            const compressedFile = await imageCompression(actualFile, options);
            const formBody = new FormData();
            formBody.append("file", compressedFile);
            formBody.append("imgName", imgName);

            axios.post(comm.SERVER_URL + "/member/upload", formBody).then((imgUpRes) => {
                props.fire();
            })
        } catch (ex) {
            console.log(" ex >>> ", ex + "");
        }

    }

    const handleFileInputChange = (ev: any) => {
        let imageFile = ev.target.files[0];
        setMemberImg(URL.createObjectURL(imageFile));
    }

    const fileInputStyle = {
        width: '90px'
    }

    const registerSpon = (ev: any) => {
        console.log("register-spon");
        setAddSponsorModalOpen(true);
    }

    const registerSupport = (ev: any) => {
        console.log("register-support");
        setAddSupportModalOpen(true);
    }

    const AddSponsorTransitionsModal = () => {
        const classes = useStyles();
        const handleOpen = () => {
            setAddSponsorModalOpen(true);
        };
        const handleClose = () => {
            setAddSponsorModalOpen(false);
        };
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className="flex flex-col items-center justify-items-center overflow-scroll"
                open={addSponsorModalOpen}
                onClose={handleClose}
                // disableScrollLock={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addSponsorModalOpen}>
                    <AddSponsor fire={() => {
                        setAddSponsorModalOpen(false)
                    }} memberId={props.memberId ? props.memberId : 0} />
                </Fade>
            </Modal>
        );
    }

    const onClickLifeLongMember = (ev: any) => {
        setCheckedLifelongMember(!checkedLifelongMember);
    }

    const onClickLClubMember = (ev: any) => {
        setCheckedlclubMember(!checkedlclubMember);
    }

    const onClickCheckedSendSMS = (ev: any) => {
        setCheckSendSMS(!checkSendSMS);
    }

    const onInitDeviceId = (ev: any) => {
        setInitDeviceId(!initDeviceId);
    }

    const onClickChongjae = (ev: any) => {
        setCheckedChongjae(!checkedChongjae);
    }

    const onClickBuChongjae = (ev: any) => {
        setCheckedBuChongjae(!checkedBuChongjae);
    }

    const AddSupportTransitionsModal = () => {
        const classes = useStyles();
        const handleClose = () => {
            setAddSupportModalOpen(false);
        };
        return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modalMedium}
                open={addSupportModalOpen}
                onClose={handleClose}
                // disableScrollLock={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={addSupportModalOpen}>
                    <AddSupport fire={() => {
                        setAddSupportModalOpen(false)
                    }} memberId={props.memberId ? props.memberId : 0} />
                </Fade>
            </Modal>
        );
    }

    const closeModal = () => {
        props.fire();
    };

    const checkDisableCheckBox = (checkBoxName:string) => {
        if(checkBoxName == '일반회원'){
            if(selectedGFCheckBox == "가족회원"){
                return true
            }
        }

        if(checkBoxName == "가족회원"){
            if(selectedGFCheckBox == "일반회원"){
                return true
            }
        }

        if(checkBoxName == "재입회원"){
            if(selectedTRCheckBox == "전입회원"){
                return true
            }
        }

        if(checkBoxName == "전입회원"){
            if(selectedTRCheckBox == "재입회원"){
                return true
            }
        }
    }
    
    const handleMemberTypeCheckBox = (ev:any) => {
        // console.log(" memberType >>> ", ev.target.value)
        if(ev.target.checked){
            setSelectedMemberTypeArr([...selectedMemberTypeArr, ev.target.name])
            if(ev.target.name == "일반회원" || ev.target.name == "가족회원"){
                setSelectedGFCheckBox(ev.target.name);
            }else if(ev.target.name == "재입회원" || ev.target.name == "전입회원"){
                setSelectedTRCheckBox(ev.target.name);
            }
        }else{
            setSelectedMemberTypeArr(selectedMemberTypeArr?.filter((each :string)  => {
                return ev.target.name != each
            }))
            if(ev.target.name == "일반회원" || ev.target.name == "가족회원"){
                setSelectedGFCheckBox("");
            }else if(ev.target.name == "재입회원" || ev.target.name == "전입회원"){
                setSelectedTRCheckBox("");
            }
        } 
    }

    const rightUpperUX = () => {
        // console.log("checkDisableCheckBox >> ", selectedMemberTypeArr.includes("일반회원"))
        return (
            <div className='flex flex-row mt-4 lg:ml-6 lg:mt-0'>
                <div className="flex flex-col w-36 lg:w-42">
                    <div className={props.memberId != -1 ? "" : "hidden"}>
                        <span>협찬</span>
                        <button type="button" className="mr-8 rounded-md border-green-600 border-solid border-2 border-opacity-60 w-32 h-12
                hover:bg-green-200 mt-2" onClick={registerSpon}>협찬 등록</button>
                    </div>
                    <span className="mt-3">회원 구분</span>
                    <div className='flex flex-col ml-1 items-center mt-1 mr-2'>
                        <div className='flex flex-row items-center'>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("일반회원")} checked={selectedMemberTypeArr?.includes("일반회원")} name="일반회원" className="h-6 w-6 mr-1 text-sm" 
                                onChange={(ev) => handleMemberTypeCheckBox(ev)} />일반
                            </label>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("가족회원")} 
                                checked={selectedMemberTypeArr?.includes("가족회원")} name="가족회원" className="h-6 w-6 ml-4 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />가족
                            </label>
                        </div>
                        <div className='flex flex-row items-center mt-1'>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("재입회원")} 
                                checked={selectedMemberTypeArr?.includes("재입회원")} name="재입회원" className="h-6 w-6 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />재입
                            </label>
                            <label className='flex items-center text-sm'>
                                <input type="checkbox" disabled={checkDisableCheckBox("전입회원")} 
                                checked={selectedMemberTypeArr?.includes("전입회원")} name="전입회원" className="h-6 w-6 ml-4 mr-1 text-sm" 
                                onChange={(ev) => {handleMemberTypeCheckBox(ev)}} />전입
                            </label>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-36 lg:w-42 ">
                    <div className={props.memberId != -1 ? "" : "hidden"}>
                        <span>찬조</span>
                        <button type="button" className="mr-8 rounded-md border-green-600 border-solid border-2 border-opacity-60 w-32 h-12
                     hover:bg-green-200 mt-2" onClick={registerSupport}>찬조 등록</button>
                    </div>
                    <span className="mt-3">성별</span>
                    <Select
                        value={selectedGender}
                        className="mt-2 w-32 lg:w-36 h-8"
                        name="inputGender"
                        onChange={(ev) => {
                            console.log("ev 22222 >> ", ev)
                            if (ev) {
                                setSelectedGender(ev)
                            } else {
                                setSelectedGender({ value: 0, label: "성별 선택" })
                            }
                        }}
                        options={gender}
                        ref={register}
                    />
                </div>
            </div>
        )
    }


    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className="h-196 lg:h-144 border-gray-800 border-2 bg-white overflow-auto">
                <div className="relative">
                    <div className="absolute top-3 right-3 " onClick={closeModal}>
                        <CloseIcon />
                    </div>
                </div>
                <div className="flex m-4 lg:m-8 flex-col lg:flex-row">
                    <div className="flex flex-col lg:mr-10">
                        <div className="flex flex-row items-center w-62 lg:justify-between">
                            <span className="w-16">사진</span>
                            <input type="file" ref={register} name="inputPic" id="inputPic" accept="image/*" className='w-22' onChange={handleFileInputChange} />
                        </div>
                        <img src={memberImg} className="w-32 h-32 mt-2 lg:w-64 lg:h-64 lg:mt-5" />
                    </div>

                    <div className="w-100w">
                        <div className="flex flex-row items-start">
                            <div className="flex flex-col w-32 lg:w-36">
                                <span>이름</span>
                                <input type="text" name="inputName" className="input mt-2" ref={register} />

                                <span className="mt-2">영문 이름</span>
                                <input type="text" name="inputNameEnglish" className="input mt-2" ref={register} />
                            </div>
                            <div className="flex flex-col w-32 lg:w-36 ml-3 lg:ml-6">
                                <span>입회일</span>
                                <input type="date" name="inputMemberDate" className="input-date mt-2 w-36" ref={register} />

                                <span className="mt-2">회원 번호</span>
                                <input type="text" name="inputMemberNo" className="input mt-2 w-32 lg:w-36" ref={register} />
                            </div>
                            {windowSize.current[0] >= 1024 ? rightUpperUX() : <div></div>}
                        </div>
                        {windowSize.current[0] < 1024 ? rightUpperUX() : <div></div>}
                        <div className="flex flex-row items-start mt-6">
                            <div className="flex flex-col w-40 lg:w-44">
                                <span className='font-semibold'>클럽 직책</span>
                                <Select
                                    value={getClubPositionData(selectedClubPosition)}
                                    className="mt-2"
                                    name="inputPositionClub"
                                    onChange={onSelectionClubPositionChange}
                                    options={clubOption}
                                    ref={register}
                                />
                                <div className="flex flex-row items-center mt-2">
                                    <input type="text" name="inputPositionFreeClub" ref={register} className="w-40 lg:w-44 focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors" />
                                </div>
                                <div className={isClubChairmanSlogan ? "flex flex-col justify-center mt-2" : "hidden"}>
                                    <span className='text-sm'>회장 슬로건</span>
                                    <input type="text" name="inputClubChairmanSlogan" placeholder="회장 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors w-36 lg:w-44" ></input>
                                </div>

                                <div className="flex flex-col items-start lg:mt-6 mt-2">
                                    역대 회장
                                    <Select
                                        isMulti
                                        // defaultValue={null} 
                                        value={selectedFchMan}
                                        className="mt-1 w-40 lg:w-44"
                                        name="inputFormerChairmans"
                                        onChange={onSelectionFormerChairmanChange}
                                        options={formerChairmanOption}
                                        ref={register}
                                    />
                                </div>
                                <div className="flex flex-col mt-4 lg:hidden">
                                    <label className="flex flex-row items-center ">
                                        <input type="checkbox" name="lifelongMember" value="checkedLifelongMember" checked={checkedLifelongMember} className="w-6 h-6 mr-2 " ref={register} onClick={onClickLifeLongMember} />평생 회원
                                    </label>
                                </div>

                            </div>
                            <div className="flex flex-col ml-4 lg:ml-8 w-40 lg:w-44">
                                <span className='font-semibold'>지구 직책</span>
                                <Select
                                    value={getJiguPositionData(selectedJiguPosition)}
                                    className="mt-2"
                                    name="inputPositionJigu"
                                    onChange={onSelectionJiguPositionChange}
                                    options={jiguOption}
                                    ref={register}
                                />
                                <div className='mt-2'></div>
                                <div className={isChongJaeSlogan ? "flex flex-row items-center" : "hidden"}>
                                    <input type="text" name="inputChongjaeSlogan" placeholder="총재 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors lg:w-54" ></input>
                                </div>
                                <div className="flex flex-row items-center">
                                    <input type="text" name="inputPositionFreeJigu"
                                        ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors" ></input>
                                </div>
                                <div className="flex flex-col items-start mt-2">
                                    <div className='w-22 text-sm'>
                                        역대지구총재
                                    </div>
                                    <Select
                                        isMulti
                                        value={selectedFjiguChMan}
                                        className="mt-1 w-36 h-8"
                                        name="inputFormerChairmans"
                                        onChange={onSelectionFormerJiguChongjaeChange}
                                        options={formerJiguChongjaeOption}
                                        ref={register}
                                    />
                                </div>
                                <div className="flex flex-col mt-4">
                                    <label className="flex flex-row items-center">
                                        <input type="checkbox" name="lclubMember" value="checkedlclubMember" checked={checkedlclubMember} className="w-6 h-6 mr-2 " ref={register} onClick={onClickLClubMember} />사자회 멤버
                                    </label>
                                    {checkedlclubMember ?
                                        <div className="flex flex-row ml-6 items-center">
                                            <span className="mr-3 ml-3">직책</span>
                                            <select
                                                className="input mt-2"
                                                name="inputPositionLclub"
                                                onChange={onSelectionLClubPositionChange}
                                                ref={register}
                                            >
                                                <option value="0" label="선택">직책 선택</option>
                                                <option value="1" label="회장">회장</option>
                                                <option value="2" label="총무">총무</option>
                                                <option value="3" label="부회장">부회장</option>
                                                <option value="4" label="감사">감사</option>
                                                <option value="5" label="고문">고문</option>
                                                <option value="6" label="회원">회원</option>
                                                <option value="7" label="준회원">준회원</option>
                                            </select>
                                        </div> : null
                                    }

                                </div>
                            </div>
                            <div className="flex flex-col ml-8 w-44">
                                <span className='font-semibold'>지역 직책</span>
                                <Select
                                    value={getJiyeokPositionData(selectedJiyeokPosition)}
                                    className="mt-2"
                                    name="inputPositionJiyeok"
                                    onChange={onSelectionJiyeokPositionChange}
                                    options={jiyeokOption}
                                    ref={register}
                                />
                                <div className={isBuChongJaeSlogan ? "flex flex-row items-center mt-2" : "hidden"}>
                                    <input type="text" name="inputBuChongjaeSlogan" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors w-54" ></input>
                                </div>
                                <div className="flex flex-row items-center mt-2">
                                    <input type="text" name="inputPositionFreeJiyoek" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors" ></input>
                                </div>
                                <div className="flex flex-col mt-10">
                                    <label className="flex flex-row items-center mt-4">
                                        <input type="checkbox" name="lifelongMember" value="checkedLifelongMember" checked={checkedLifelongMember} className="w-6 h-6 mr-2 " ref={register} onClick={onClickLifeLongMember} />평생 회원
                                    </label>
                                </div>
                            </div>
                        </div>
                        {/* <div className="flex flex-col w-44 mt-4 lg:hidden">
                            <span className='font-semibold'>지역 직책</span>
                            <Select
                                value={getJiyeokPositionData(selectedJiyeokPosition)}
                                className="mt-2"
                                name="inputPositionJiyeok"
                                onChange={onSelectionJiyeokPositionChange}
                                options={jiyeokOption}
                                ref={register}
                            />
                            <div className={isBuChongJaeSlogan ? "flex flex-row items-center mt-2" : "hidden"}>
                                <input type="text" name="inputBuChongjaeSlogan" placeholder="부총재 슬로건 입력" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors w-54" ></input>
                            </div>
                            <div className='text-sm mt-2'>직접 입력</div>
                            <div className="flex flex-row items-center">
                                <input type="text" name="inputPositionFreeJiyoek" ref={register} className="focus:outline-none focus:border-gray-500 p-1 border-2  text-base border-gray-200 transition-colors" ></input>
                            </div>
                        </div> */}

                        <div className="flex flex-row items-center mt-5 lg:mt-10">
                            <div className="flex flex-col w-28 lg:w-1/2">
                                <span>직업</span>
                                <input type="text" name="inputJob" className="input mt-2" ref={register} />
                            </div>
                            <div className="flex flex-col w-full ml-6">
                                <span>주소</span>
                                <input type="text" name="inputAddress" className="input mt-2 w-60 lg:w-128" ref={register} />
                            </div>
                        </div>
                        <div className="mt-6 font-semibold">연락처</div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-5">핸드폰</span>
                                <input type="number" name="inputMobile" placeholder="숫자만 예)01090111234" className="input w-44 lg:ml-3 lg:w-64" ref={register} required />
                            </div>

                            <div className='flex flex-col ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-5">자택</span>
                                <input type="number" name="inputHome" placeholder="지역번호 포함 숫자만 예) 028825959" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>

                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20">사업장</span>
                                <input type="number" name="inputBusiness" placeholder="지역번호 포함 숫자만 예) 031-5345959" className="input w-44 lg:ml-3 lg:w-64" ref={register} />
                            </div>
                            <div className='flex flex-col lg:ml-3 lg:flex-row lg:items-center'>
                                <span className="w-20 lg:ml-5">이메일</span>
                                <input type="text" name="inputEmail" className="input ml-3 w-44 lg:w-64" ref={register} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-row items-center justify-center'>
                    <label className={props.memberId != -1 ? "flex flex-row items-center justify-center mr-3 lg:mr-14" : "hidden"}>
                        <input type="checkbox" name="checkSendSMS" value="checkSendSMS" checked={checkSendSMS} className="w-6 h-6 mr-2 " ref={register} onClick={onClickCheckedSendSMS} />앱설치 안내 문자
                    </label>
                    <label className={props.memberId != -1 ? "flex flex-row items-center justify-center mr-8" : "hidden"}>
                        <input type="checkbox" name="initDeviceId" value="initDeviceId" checked={initDeviceId} className="w-6 h-6 mr-2 " ref={register} onClick={onInitDeviceId} />디바이스 초기화
                    </label>
                </div>
                <div className="flex flex-row justify-center items-center h-32">
                    <button type="submit" className="lg:mr-8 mr-3 rounded-md lg:rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-32 h-10 lg:w-48 lg:h-16 hover:bg-blue-200">{props.memberId === -1 ? "회원 추가" : "회원 수정"}</button>
                    <button type="button" className="lg:ml-8 ml-3 rounded-md lg:rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-32 h-10 lg:w-48 lg:h-16 hover:bg-red-200" onClick={props.fire}>취소</button>
                </div>
            </form>
            {AddSponsorTransitionsModal()}
            {AddSupportTransitionsModal()}
        </div>
    )
}

export default AddMember;