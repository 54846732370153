import React from 'react';
import './aboutLions.css';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import comm from '../../common';

interface LandingProps {
    club: string,
    id: number,
}

const Landing: React.FC<LandingProps> = (props) => {
    const {deviceId, isLatestVersion, fcmToken} = useParams<any>();
    console.log(" deviceID > " , deviceId);
    console.log(" isLatestVersion > " , isLatestVersion);
    console.log(" fcmToken > " , fcmToken);
    let history = useHistory();
    
    window.addEventListener("flutterInAppWebViewPlatformReady", function(event) {
        console.log(" event on !!!! ");
        
    });

    setTimeout(() => {
        axios.get(comm.SERVER_URL+"/member/getByDeviceId", {
            params:{
                id:deviceId
            }
        }).then(res => {
            if(isLatestVersion == "true"){
                if(res.data){    // DB에 등록되어 있다. (즉 회원 가입이 되어 있다.)
                    let data = res.data;        
                    const userPath = data.id + "&" + data.belongToClub + "&" + data.belongToJidae + "&" + data.belongToJiyeok + "&" + data.belongToJigu;
                    // 앱에 접속할 때마다 fcmToken을 업데이트 해준다.
                    axios.put(comm.SERVER_URL+"/member/addFcmToken", {
                        memberId:data.id,
                        fcmToken:fcmToken,
                    }).then(res => {    
                    
                    });
                    // 일단 356E지구이면
                    if(data.belongToJigu == 3){
                        history.push("/jiguClubSelectScreen/"+userPath);  // 새로 만든 UI로 이동 
                        // history.push("/executiveList/"+userPath);  // 새로 만든 UI로 이동 
                    }else{
                        history.push("/clubList/"+userPath);  // 클럽리스트로 이동 
                    }
                }else{
                    history.push("/login/"+deviceId);  // 로긴 화면 이동 (아직 deviceId 등록 안된) 
                }
            }else{     // AppStore로 이동 
                //to do: AppStore 이동 로직  --> Flutter에서 
            }
        })
    },1500);

    return (
        <div className="flex flex-col h-screen bg-white items-center justify-center items-center">
            <span className='text-5xl font-bold text-lions-blue'>Lions</span>
        </div>
    )
}

export default Landing;
