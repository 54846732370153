import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { Receiver, SmsDto } from '../common/dto';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';

export interface IReceiver{
    name:string,
    mobileNum:string,
    position:string,
  }
  
  export interface ISmsHistory{
    sender:string,
    content: string,
    receivers: Array<IReceiver>,
    sendingTime:string, 
    receiverGrop:Array<string>,
  }
  

const SmsHistory = () => {
    const history: any = useHistory();
    const [smsHistoryArr, setSmsHistoryArr] = useState<Array<ISmsHistory>>();
    const groupValue = useContext(GroupContext);
    console.log("groupValue >> ", groupValue);

    useEffect(() => {
        axios.get(comm.SERVER_URL+"/sms/sendHistory/"+groupValue.jiguId).then(res =>{
            console.log(" >>>>>>>> ", res.data);
            setSmsHistoryArr(res.data.results);
        });
    }, []);

    const goToDetail = (ev :any, id:number) => {
        history.push('/smsHistoryDetial',{id:id})
    }

    return (<div className='flex flex-col items-center max-w-xl'>
        <div className=''>
        {
            smsHistoryArr?.map(eachHistory => {
                let nicks = "";
                eachHistory.receivers.forEach((nick, index) => {
                    if(index == eachHistory.receivers.length-1){
                        nicks = nicks + nick.name;
                    }else{
                        nicks = nicks + nick.name +", ";
                    }
                })
                
                return (<div className='flex flex-col m-3 border-gray-400 rounded-md border w-9/10 p-3' >
                    <span className=''>{eachHistory.sendingTime}</span>
                    <span className=''>보내는 사람:{eachHistory.sender}</span>
                    <span className=''>받는 사람:{nicks}</span>
                    <span className=''>*내용</span>
                    <div className=''>
                        {eachHistory.content}
                    </div>
                </div>)
            })
        }
        </div>

    </div>)
}

export default SmsHistory;

